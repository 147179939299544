import React, {Component} from "react";
import {Box, CssBaseline, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import "moment/locale/es";

export default class PatientModalForm extends Component {

	constructor(props) {
		super(props);
		let {patient} = props;
		patient.sex = patient.sex || 1;
		patient.bloodType = patient.bloodType || 'O -';
		patient.name = patient.name || '';
		patient.nhc = patient.nhc || '';
		patient.birthDate = patient.birthDate || null;
		patient.dni = patient.dni || '';
		this.state = {
			sex: patient.sex,
			birthDate: patient.birthDate,
			bloodType: patient.bloodType,
			patient: patient
		}
	}

	render() {
		let {error} = this.props;
		let {patient, sex, birthDate, bloodType} = this.state;
		return (
			<Box sx={{flexGrow: 1}}>
				<CssBaseline/>
				<Grid container spacing={2}>
					<Grid item xs={3}>
						<TextField id="nhc" variant="outlined" label="N° Historia Clinica" value={patient.nhc}
						           margin="normal" fullWidth sx={{marginBottom: 0}} error={error["nhc"]} type="number"
						           onChange={this.updateInputValue} required={true} size={"small"}
						           InputProps={{inputProps: {min: 0}}}/>
					</Grid>
					<Grid item xs={6}>
						<TextField id="name" variant="outlined" label="Apellido y nombre" value={patient.name}
						           margin="normal" fullWidth sx={{marginBottom: 0}}
						           error={error["name"]} autoFocus
						           onChange={this.updateInputValue} required={true} size={"small"}/>
					</Grid>
					<Grid item xs={3}>
						<FormControl fullWidth margin="normal" size="small" sx={{marginBottom: 0}}>
							<InputLabel id="sex-label">Sexo</InputLabel>
							<Select labelId="sex-label" id="sex" value={sex} label="Sexo"
							        onChange={(e) => {
								        patient.sex = e.target.value;
								        this.setState({sex: patient.sex});
							        }}>
								<MenuItem value={1}>Masculino</MenuItem>
								<MenuItem value={2}>Femenino</MenuItem>
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={6}>
						<TextField id="dni" variant="outlined" label="D.N.I." value={patient.dni}
						           margin="none" fullWidth onChange={this.updateInputValue} size={"small"}
						           type="number" InputProps={{inputProps: {min: 0}}}/>
					</Grid>
					<Grid item xs={6}>
						<FormControl fullWidth margin="none" size="small">
							<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
								<DatePicker label="Fecha de nacimiento" maxDate={new Date()}
								            value={birthDate} onChange={(v) => {
									patient.birthDate = v;
									this.setState({birthDate: patient.birthDate});
									delete error["birth_date"];
								}} renderInput={(params) => <TextField size="small" {...params}
								                                       error={error["birth_date"]}/>}/>
							</LocalizationProvider>
						</FormControl>
					</Grid>

					<Grid item xs={6}>
						<FormControl fullWidth margin="none" size="small">
							<InputLabel id="blood-type-label">Grupo sanguineo</InputLabel>
							<Select labelId="blood-type-label" value={bloodType} label="Grupo sanguineo"
							        onChange={(e) => {
								        patient.bloodType = e.target.value;
								        this.setState({bloodType: patient.bloodType});
							        }}>
								<MenuItem value="O -">O -</MenuItem>
								<MenuItem value="O +">O +</MenuItem>
								<MenuItem value="A -">A -</MenuItem>
								<MenuItem value="A +">A +</MenuItem>
								<MenuItem value="B -">B -</MenuItem>
								<MenuItem value="B +">B +</MenuItem>
								<MenuItem value="AB -">AB -</MenuItem>
								<MenuItem value="AB -">AB +</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={6}>
						<TextField id="address" variant="outlined" label="Direccion" value={patient.address}
						           margin="none" fullWidth onChange={this.updateInputValue} size={"small"}/>
					</Grid>

					<Grid item xs={6}>
						<TextField id="city" variant="outlined" label="Localidad" value={patient.city}
						           margin="none" fullWidth onChange={this.updateInputValue} size={"small"}/>
					</Grid>
					<Grid item xs={6}>
						<TextField id="province" variant="outlined" label="Provincia" value={patient.province}
						           margin="none" fullWidth onChange={this.updateInputValue} size={"small"}/>
					</Grid>

					<Grid item xs={6}>
						<TextField id="email" variant="outlined" label="E-Mail" value={patient.email}
						           margin="none" fullWidth onChange={this.updateInputValue} size={"small"}/>
					</Grid>
					<Grid item xs={6}>
						<TextField id="health_care_name" variant="outlined" label="Obra Social"
						           value={patient.health_care_name} margin="none" fullWidth
						           onChange={this.updateInputValue} size={"small"}/>
					</Grid>
				</Grid>
			</Box>
		);
	}

	updateInputValue = (event) => {
		delete this.props.error[event.target.id];
		this.props.patient[event.target.id] = event.target.value;
		this.setState({patient: this.props.patient});
	}
}