import React, {useRef, useEffect} from 'react'

const Canvas = props => {

    const canvasRef = useRef(null)

    useEffect(() => {
        const canvas = canvasRef.current

        let img = new Image();
        img.src = "/graphs/" + props.graph.src;

        img.onload = function () {
            const context = canvas.getContext('2d')
            context.drawImage(img, 0, 0, props.graph.width, props.graph.height);

            let {graphs} = props;

            for (let i = 0; i < graphs.length; i++) {
                let g = graphs[i];

                if (!props.graph.src.includes(g["name"])) {
                    continue;
                }

                let points = g["points"];
                if (points === undefined || points === null)
                    return;

                for (let j = 0; j < points.length; j++) {
                    let currentPoint = points[j];

                    if (j !== 0 && j !== points.length) {
                        let prevPoint = points[j - 1];
                        context.strokeStyle = "#0000ff";
                        context.beginPath();
                        context.moveTo(prevPoint.x, prevPoint.y);
                        context.lineTo(currentPoint.x, currentPoint.y);
                        context.stroke();
                    }

                    context.fillStyle = "#0000ff";
                    context.beginPath();
                    context.arc(currentPoint.x, currentPoint.y, 4, 0, 2 * Math.PI);
                    context.fill();
                }
            }

            //context.fillRect(0, 0, props.style.width, props.style.height)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.graph])

    return <canvas ref={canvasRef} width={props.graph.width} height={props.graph.height} {...props}/>
}

export default Canvas