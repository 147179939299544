import React, {Component} from "react";
import {Box, Button, Divider, Modal, Step, StepLabel, Stepper} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import PatientModalForm from "./PatientModalForm";
import ParentsModalForm from "./ParentsModalForm";
import BackgroundDataModalForm from "./BackgroundDataModalForm";
import Constants from "../../../Util/Constants";
import Util from "../../../Util/Util";

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	//width: 800,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 2,
	outline: 0,
};

const steps = ['Paciente', 'Padres', 'Antecedentes'];

export default class NewPatientModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			activeStep: 0,
			skipped: new Set(),
			patient: {},
			error: {},
			background: {},
			enableSaveButton: true
		};
	}

	render() {
		let {open} = this.props;
		let {activeStep, enableSaveButton, patient} = this.state;
		patient = this.props.patient && Object.keys(this.props.patient).length !== 0 ? this.props.patient : patient;
		return (
			<Modal
				open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
				closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{timeout: 500}} id={patient.nhc}>
				<Box sx={style}>
					<Stepper activeStep={activeStep} alternativeLabel>
						{steps.map((label, index) => {
							const stepProps = {};
							const labelProps = {};
							if (this.isStepSkipped(index)) {
								stepProps.completed = false;
							}
							return (
								<Step key={label} {...stepProps}>
									<StepLabel {...labelProps}>{label}</StepLabel>
								</Step>
							);
						})}
					</Stepper>
					<React.Fragment>
						{this.getModalForm()}
						<Divider/>
						<Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
							<Button color="inherit" disabled={activeStep === 0} onClick={this.handleBack} sx={{mr: 1}}>
								Atras
							</Button>
							<Button color="error" onClick={() => {
								this.setActiveStep(0)
								this.setState({patient: {}, background: {}})
								this.props.closeModal();
							}} sx={{mr: 1}}>
								Cancelar
							</Button>
							<Box sx={{flex: '1 1 auto'}}/>
							{/*activeStep !== steps.length - 1 &&
							<Button color="inherit" onClick={this.handleSkip} sx={{mr: 1}}>
								Saltar
							</Button>
							*/}

							<Button onClick={this.handleNext} disabled={!enableSaveButton}>
								{activeStep === steps.length - 1 ? 'Guardar' : 'Siguiente'}
							</Button>
						</Box>
					</React.Fragment>
				</Box>
			</Modal>
		);
	}

	isStepSkipped = (step) => {
		return this.state.skipped.has(step);
	};

	setActiveStep = (activeStep) => {
		this.setState({activeStep});
	}

	handleNext = () => {
		let {skipped, activeStep, patient, background} = this.state;
		patient = Object.keys(patient).length === 0 ? this.props.patient : patient;
		if (activeStep === steps.length - 1) {
			if (!this.validateStep(patient))
				return;

			this.setState({enableSaveButton: false});

			if (background[Constants.FAMILY])
				background[Constants.FAMILY].forEach(x => patient[x] = true);
			if (background[Constants.JUST_BORN])
				background[Constants.JUST_BORN].forEach(x => patient[x] = true);

			Util.savePatient(patient, result => {
				this.setState({enableSaveButton: true});
				if (result.status === 200) {
					this.props.closeModal("Paciente guardado con exito. NHC: " + result.data.id);
					this.setState({activeStep: 0});
				}
			});
			return;
		}

		let newSkipped = skipped;
		if (this.isStepSkipped(this.state.activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}

		this.setActiveStep(activeStep + 1);
		this.setState({skipped: newSkipped});
	};

	handleBack = () => {
		this.setActiveStep(this.state.activeStep - 1);
	};

	handleSkip = () => {
		this.setActiveStep(this.state.activeStep + 1);
		const newSkipped = new Set(this.state.skipped.values());
		newSkipped.add(this.state.activeStep);
		this.setState({skipped: newSkipped});
	};

	getModalForm() {
		let {activeStep, patient, error, background} = this.state;
		patient = this.props.patient && Object.keys(this.props.patient).length !== 0 ? this.props.patient : patient;
		switch (activeStep) {
			case 0:
				return <PatientModalForm patient={patient} error={error}/>
			case 1:
				return <ParentsModalForm patient={patient}/>
			case 2:
				return <BackgroundDataModalForm patient={patient} background={background}/>
			default:
				console.log("Invalid step");
		}
	}

	validateStep(patient) {
		if (!patient.name || patient.name === '') {
			this.setState({error: {'name': true}, activeStep: 0});
			return false;
		}
		if (!patient.nhc || patient.nhc === '') {
			this.setState({error: {'nhc': true}, activeStep: 0});
			return false;
		}
		if (!patient.birthDate || patient.birthDate === '') {
			this.setState({error: {'birth_date': true}, activeStep: 0});
			return false;
		}
		return true;
	}
}